import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Truncate from 'react-truncate'
import striptags from 'striptags'


export default ({ article }) => (
  <Link to={`/blog/${article.slug}`}>
    {article.image !== null ? <Img className="image" alt="" fluid={article.image.fluid} /> : null}
    <p className="title">
      {article.title}
    </p>
    <p
      className="body"
    >
      {article.summary.summary}
    </p>
  </Link>
)
