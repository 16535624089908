import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import { StoreButton } from '../components/store-button'
import Img from 'gatsby-image'
import Slider from "react-slick";
import BackgroundImage from 'gatsby-background-image'
import moment from "moment";
import specialFormatter from '../utility/special-formatter'

class RootIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false
    };
  }

  componentDidMount() {
    this.setState({
      mounted: true
    });
  }

  render() {
    const siteTitle = get(this.props, 'data.contentfulSite.htmlTitle')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const games = get(this, 'props.data.allContentfulGame.edges')
    const storeItems = get(this, 'props.data.allContentfulStoreItems.edges')
    const site = get(this, 'props.data.contentfulSite')
    const sliderSettings = {
      infinite: true,
      arrows: false,
      dots: true,
      slidesToShow: 5,
      slidesToScroll: 5,
        responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };


    let slider = null;

    if (this.state.mounted) {
      slider = (
        <Slider {...sliderSettings} className="home-store-items">
          {storeItems.map(({ node }) => {
            return (
              <div key={node.name}>
                <a href={node.url}>
                  <img src={node.imageUrl} />
                  <span>{node.name}</span>
                </a>
              </div>
            )
          })}
        </Slider>
      )
    }

    return (
      <Layout location={this.props.location} >
        <Helmet title={siteTitle} />
        <div className="home-title">
          <div className="container">
            <h1>{site.title}</h1>
            <p className="tagline">{site.subtitle}</p>
            <hr />
            <ul className="home-blog">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
            </ul>
            <h2 className="wy-text-center" style={{ paddingTop: 32 }}><span className="fas fa-arrow-circle-down"></span> Our Games <span className="fas fa-arrow-circle-down"></span></h2>
          </div>
        </div>

        <ul className="home-games">
          {games.map(({ node }) => {
            return (
              <li key={node.slug} className={node.slug} style={{background: `url(${node.homeArt.fixed.src})`, backgroundSize: 'cover'}}>
                <div className="container">
                  <Link to={`/games/${node.slug}/`} className="poster" style={{ maxWidth: 300, float: 'left', marginRight: 40 }}>
                    <Img fixed={node.boxArt.fixed} />
                  </Link>
                  <Link to={`/games/${node.slug}/`} className="text">
                    <h2>{node.name}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: (node.about !== null) ? specialFormatter(node.about.childContentfulRichText.html) : null,
                      }}
                    />
                  </Link>
                  <hr style={{ borderColor: node.color }} />
                  <h3>Available on</h3>
                  {(node.steam !== null) ? <StoreButton link={node.steam} store="steam" />: null}
                  {(node.nintendoStore !== null) ? <StoreButton link={node.nintendoStore} store="nintendoStore" />: null}
                  {(node.psStore !== null) ? <StoreButton link={node.psStore} store="psStore" />: null}
                  {(node.msStore !== null) ? <StoreButton link={node.msStore} store="msStore" />: null}
                  {(node.epicStore !== null) ? <StoreButton link={node.epicStore} store="epicStore" />: null}
                  {(node.macAppStore !== null) ? <StoreButton link={node.macAppStore} store="macAppStore" />: null}
                </div>
              </li>
            )
          })}
        </ul>
        
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(limit: 3, sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          summary {
            summary
          }
          slug
          bodyLegacy {
            bodyLegacy
          }
          body {
            childContentfulRichText {
              html
            }
          }
          image {
            fluid(maxWidth: 350, maxHeight: 200, quality: 80, resizingBehavior: FILL) {
              src
            }
          }
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
    allContentfulGame(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          name
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          color
          steam
          psStore
          macAppStore
          msStore
          epicStore
          nintendoStore
          about {
            childContentfulRichText {
              html
            }
          }
          epicStore
          boxArt {
            fixed(width: 285) {
              ...GatsbyContentfulFixed
            }
          }
          homeArt {
            fixed(width: 1200, height: 500, quality: 80) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
    allContentfulStoreItems(sort: { fields: [sortPriority], order: DESC }) {
      edges {
        node {
          name
          url
          imageUrl
        }
      }
    }
    contentfulSite {
      title
      subtitle
      htmlTitle
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
